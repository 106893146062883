import './App.css';
import React from 'react';
import { useParams } from 'react-router-dom'
import { AuthProvider, Descope } from '@descope/react-sdk';
import Welcome from './components/Welcome';

const getCookie = (name: string): string | null => {
	const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
	if (match) return match[2];
	return null;
}

const getDomainFromCookie= (): string | null =>{
	const workspaceUrl = getCookie('workspace-url');
	if (!workspaceUrl) {
		return '';
	}
	const domains = workspaceUrl.split('|');

	return domains[domains.length - 1] || '';
}

const App = () => {
	const { tenant } = useParams();
	let projectId = '';
	projectId = process.env.REACT_APP_DESCOPE_PROJECT_ID || ''
	const baseUrl = process.env.REACT_APP_DESCOPE_BASE_URL
	const baseStaticUrl = process.env.REACT_APP_DESCOPE_BASE_STATIC_URL
	const urlParams = new URLSearchParams(window.location.search);

	const flowId =
	urlParams.get('flow') || process.env.REACT_APP_DESCOPE_FLOW_ID || 'default-sso';

	const debug = process.env.DESCOPE_FLOW_DEBUG === 'false';

	// For Peter: Should users actually be able to change this with a URL param? 
	const backgroundColor = '#F5F7F9';

	// Collect the provider used and then pass it into the flow, via flow inputs
	let domain = '';
	if (!tenant) {
		domain = getDomainFromCookie() || '';
	}

	// Collect the provider used and then pass it into the flow, via flow inputs
	let providerCookie = '';
	if (tenant === 'aws' || domain?.endsWith('cloud.databricks.com')) {
		providerCookie = 'aws';
	} else if (tenant === 'gcp' || domain?.endsWith('gcp.databricks.com')) {
		providerCookie = 'gcp';
	} else if (tenant === 'azure' || domain?.endsWith('azuredatabricks.net')) {
		providerCookie = 'azure';
	}
	return (
		<AuthProvider projectId={projectId} baseUrl={baseUrl} baseStaticUrl={baseStaticUrl}>
			<div className="app" style={{ backgroundColor }}>
				{projectId && flowId ? (
					<div className="descope-container" data-testid="descope-component">
						<Descope flowId={flowId} debug={debug} client={{provider: providerCookie}}/>
					</div>
				) : (
					<Welcome />
				)}
			</div>
		</AuthProvider>
	);
};

export default App;